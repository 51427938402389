import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { MainButton } from '../Buttons/Buttons';
import { handleLogin } from '../services/login';
import { useMessage } from '../MessageContext/MessageContext';

const Login = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!username || !password) {
        throw new Error('Usuário e senha são obrigatórios.')
      }

      const isLoginSuccessful = await handleLogin(username, password);

      if (isLoginSuccessful) {
        navigate('/classes');
      } else {
        setMessage('Login failed');
      }
    }
    catch (error) {
      showMessage(error.message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <div className="login-container">
      <h1>SuSsy</h1>
      <h2>Sistema de submissões da Unicamp</h2>
      <div className="form">
        <div>
          <label htmlFor="ra">RA</label>
          <input
            type="text"
            id="ra"
            className="login-input"
            name="ra"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            className="login-input"
            id="password"
            name="password"
            value={password}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <MainButton text={'Acessar'} onClick={handleSubmit} />
        {message && <p>{message}</p>}
        {/*TODO: Por estilo nisso...*/}
      </div>
    </div>
  );
};

export default Login;
