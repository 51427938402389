import axios from 'axios';
import { Base64 } from 'js-base64';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/submissions`,
});

export const handlePostSubmission = async (
  assignmentId,
  userId,
  executableFile
) => {
  try {
    const formData = new FormData();
    formData.append('assignment_id', assignmentId);
    formData.append('user_id', userId);
    formData.append('executable', executableFile);

    const response = await api.post('/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao enviar submissão: ${message}`);
  }
};

export const handleGetSubmissionFile = async (submission_id) => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const response = await api.get(`/${submission_id}/file`, {
      responseType: 'blob',
      headers: {
        Authorization: `Basic ${credentials}`,
      }
    });

    // Get the MIME type from the server response headers
    const mimeType = response.headers['content-type'];
    const fileBlob = new Blob([response.data], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    
    const contentDisposition = response.headers['content-disposition']?.split("filename=")
    const filename = contentDisposition?.length > 1 ? contentDisposition[1].trim() : undefined;

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao recuperar arquivo da submissão: ${message}`);
  }
}

