import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
});

export const handleLogin = async (username, password) => {
  try {
    // TODO: Remove this
    if (username === 'teacher' && password === 'mock') {
      const mockedData = {
        name: 'Mocked teacher',
        role: 'TEACHER',
        username: 'teacher',
      };
      localStorage.setItem('user', JSON.stringify(mockedData));

      return mockedData;
    }

    // TODO: Remove this
    if (username === 'admin' && password === 'mock') {
      const mockedData = {
        name: 'Mocked admin',
        role: 'ADMIN',
        username: 'admin',
      };
      localStorage.setItem('user', JSON.stringify(mockedData));

      return mockedData;
    }

    const data = await api.post('/login', { username, password });

    if (data.status === 200) {
      localStorage.setItem('user', JSON.stringify(data.data));

      return true;
    }
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao fazer login: ${message}`);
  }

  return false;
};
