import axios from 'axios';
import { Base64 } from 'js-base64';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/classes`,
});

const assignmentApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/assignments`,
});

export const handleGetUserClasses = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const { data } = await api.get(`/`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });

    return data;
  }
  catch (error) {
    console.log(error);
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao buscar classes: ${message}`);
  }
};

export const handleDeleteClass = async (classId) => {
  try {
    await api.delete(`/${classId}`);
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao excluir classe: ${message}`);
  }
};

export const handleGetClassAssignments = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedClass = JSON.parse(localStorage.getItem('selectedClass'));
    const credentials = Base64.encode(`${storedUser.username}:${storedUser.role}`);
    
    // Step 1: Fetch assignments for the selected class
    const { data: assignments } = await api.get(`/${selectedClass.id}/assignments`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });

    // Step 2: Fetch submissions for each assignment concurrently
    const assignmentsWithSubmissions = await Promise.all(
      assignments.map(async (assignment) => {
        const { data: submissions } = await assignmentApi.get(`/${assignment.id}/submissions`, {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        });
        
        // Insert submissions into the assignment object
        return { ...assignment, submissions };
      })
    );

    // Return the updated assignments array
    return assignmentsWithSubmissions;
  } catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao buscar tarefas: ${message}`);
  }
};

export const handleCreateClass = async (
  name,
  studentFile,
  monitorFile,
  teacherFile
) => {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('student', studentFile);
  formData.append('monitor', monitorFile);
  formData.append('teacher', teacherFile);

  try {
    await api.post(`/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Erro ao criar a classe: ${message}`);
  }
};

export const handleEditClass = async (currentClass, newName) => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const credentials = Base64.encode(
    `${storedUser.username}:${storedUser.role}`
  );

  try {
    await api.put(
      `/${currentClass.id}`,
      { name:newName },
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
  } catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Erro ao editar a classe: ${message}`);
  }
};
