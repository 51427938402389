import React, { useState } from 'react';
import SideBarMenu from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { handleCreateAssignment } from '../../services/assignments';
import { useMessage } from '../../MessageContext/MessageContext';

const TaskForm = () => {
  const navigate = useNavigate();
  const {showMessage} = useMessage();
  const [tests, setTests] = useState([]);
  const [newTestInput, setNewTestInput] = useState(null);
  const [newTestOutput, setNewTestOutput] = useState(null);
  const [title, setTitle] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [maxSubmissions, setMaxSubmissions] = useState(1);
  const [description, setDescription] = useState(null);

  const toggleOpen = (index) => {
    setTests(
      tests.map((test, i) =>
        i === index ? { ...test, isOpen: !test.isOpen } : test
      )
    );
  };

  const removeTest = (index) => {
    setTests(tests.filter((_, i) => i !== index));
  };

  const addTest = () => {
    if (newTestInput && newTestOutput) {
      setTests([
        ...tests,
        {
          input: newTestInput,
          output: newTestOutput,
          inputName: newTestInput.name,
          outputName: newTestOutput.name,
          isOpen: false,
        },
      ]);
      showMessage('Teste adicionado.', 'success');
      setNewTestInput(null);
      setNewTestOutput(null);
    }
    else {
      showMessage('Arquivos de entrada e saída são obrigatórios para adicionar um teste.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedClass = JSON.parse(localStorage.getItem('selectedClass'));
    const formData = new FormData();

    if (!title || !dueDate || !description || !tests.length) {
      showMessage("Campos marcados (*) são obrigatórios.");
      return;
    }

    formData.append('title', title);
    formData.append('class_id', selectedClass.id);
    formData.append('due_date', dueDate);
    formData.append('max_submissions', maxSubmissions);
    formData.append('amount_tests', tests.length);
    formData.append('description', description);

    tests.forEach((test, index) => {
      formData.append(`tests[${index}][open]`, test.isOpen);
      if (test.input) formData.append(`tests[${index}][input]`, test.input);
      if (test.output) formData.append(`tests[${index}][output]`, test.output);
    });

    try {
      await handleCreateAssignment(formData);
      navigate('/assignments');
    } 
    catch (error) {
      showMessage(error.message);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />

    <form onSubmit={handleSubmit} style={styles.container}>
      {/* Left Side: Task Form */}
      <div style={styles.leftContainer}>
        <h3>Tarefas</h3>
        <div style={styles.formGroup}>
          <label>Título da tarefa *</label>
          <input
            type="text"
            style={styles.input}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Data máxima para entrega *</label>
          <input
            type="datetime-local"
            style={styles.input}
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Número máximo de submissões</label>
          <input
            type="number"
            style={styles.input}
            value={maxSubmissions}
            onChange={(e) => setMaxSubmissions(e.target.value)}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Upload enunciado *</label>
          <input
            type="file"
            style={styles.input}
            onChange={(e) => setDescription(e.target.files[0])}
          />
        </div>
        <div style={styles.formGroup}>
          <label>Upload arquivos auxiliares</label>
          <input type="file" style={styles.input} />
        </div>
        <button style={styles.saveButton}>Salvar</button>
      </div>

      {/* Right Side: Tests Upload */}
      <div style={styles.rightContainer}>
        <div style={styles.formGroup}>
          <label><b>Upload testes:</b></label>
          <br /><br />
          <label>Arquivo de entrada*:</label>
          <input
            type="file"
            placeholder='Arquivos de entrada'
            onChange={(e) => setNewTestInput(e.target.files[0])}
            style={styles.input}
            // accept=".in"
          />
          <label>Arquivo de saída*:</label>
          <input
            type="file"
            placeholder='Arquivos de saída esperada'
            onChange={(e) => setNewTestOutput(e.target.files[0])}
            style={styles.input}
            // accept=".out"
          />
          <button type="button" onClick={addTest} style={styles.addButton}>
            +
          </button>
        </div>

        <h4>Testes *</h4>
        <table style={styles.table}>
          <thead>
            <tr>
              <th>Arquivo</th>
              <th>Aberto</th>
              <th>Remover</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            {tests.map((test, index) => (
              <tr key={index}>
                <td>{`${test.inputName} | ${test.outputName}`}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={test.isOpen}
                    onChange={() => toggleOpen(index)}
                  />
                </td>
                <td>
                  <button type="button" onClick={() => removeTest(index)}>
                    ❌
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
    </div>
  );
};

// CSS in JS
const styles = {
  container: {
    display: 'flex',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  leftContainer: {
    flex: 1,
    paddingRight: '20px',
    borderRight: '1px solid #ccc',
  },
  rightContainer: {
    flex: 1,
    paddingLeft: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '8px',
    margin: '5px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  saveButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '78px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    borderBottom: '1px solid #ccc',
  },
  tableHeader: {
    textAlign: 'left',
    borderBottom: '2px solid #000',
  },
};

export default TaskForm;
