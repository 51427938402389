import React from 'react';
import './Cards.css';
import { MainButton, CreateButton } from '../Buttons/Buttons';
import { allowEdit } from '../services/permissions';

const Cards = ({
  cardElements,
  accessAction,
  Header,
  Content,
  createAction,
}) => {
  return (
    <div className="cards">
      {cardElements &&
        cardElements.map((element) => (
          <div key={element.id} className="card">
            <div className="header">
              <Header element={element} />
            </div>

            <div className="status">
              {Content && <Content element={element} />}
            </div>
            <MainButton
              text={'Acessar'}
              onClick={() => accessAction(element)}
            />
          </div>
        ))}
      {allowEdit() && <CreateButton onClick={createAction} />}
    </div>
  );
};

export default Cards;
