import axios from 'axios';
import { Base64 } from 'js-base64';

const baseURL = `${process.env.REACT_APP_BASE_URL}/assignments`;

const api = axios.create({
  baseURL:baseURL
});

export const handleDeleteAssignment = async (assignmentId) => {
  try {
    await api.delete(`/${assignmentId}`);
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao excluir tarefa: ${message}`);
  }
};

export const handleCreateAssignment = async (formData) => {
  try {
    await api.post(`/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    } );
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao criar tarefa: ${message}`);
  }
};

export const handleGetAssignmentTests = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );
    const { data } = await api.get(`/${selectedAssignment.id}/tests`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });

    return data;
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao recuperar testes desta tarefa: ${message}`);
  }
};

export const handleGetAssignmentSubmissions = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );
    const { data } = await api.get(`/${selectedAssignment.id}/submissions`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });

    return data;
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao buscar submissões da tarefa: ${message}`);
  }
};

export const handleGetAssigmentDescription = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const response = await api.get(`/${selectedAssignment.id}/description`, {
      responseType: 'blob',
      headers: {
        Authorization: `Basic ${credentials}`,
      }
    });

    // Get the MIME type from the server response headers
    const mimeType = response.headers['content-type'];
    const fileBlob = new Blob([response.data], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    
    const contentDisposition = response.headers['content-disposition']?.split("filename=")
    const filename = contentDisposition?.length > 1 ? contentDisposition[1].trim() : undefined;

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
  } catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao recuperar arquivo do enunciado:${message}`);
  }
}

export const handleGetAssigmentAuxFile = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedAssignment = JSON.parse(localStorage.getItem('selectedAssignment'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );

    const response = await api.get(`/${selectedAssignment.id}/aux`, {
      responseType: 'blob',
      headers: {
        Authorization: `Basic ${credentials}`,
      }
    });

    if (response.status < 200 || response.status > 299){
      throw new Error("Não há arquivos auxiliares para essa tarefa.");
    }

    const mimeType = response.headers['content-type'];
    const fileBlob = new Blob([response.data], { type: mimeType });
    const fileUrl = URL.createObjectURL(fileBlob);
    
    const contentDisposition = response.headers['content-disposition']?.split("filename=")
    const filename = contentDisposition?.length > 1 ? contentDisposition[1].trim() : undefined;

    // Create a temporary anchor element to trigger download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename; // Set the filename here
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(fileUrl), 1000);
  } catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao recuperar arquivo auxiliar: ${message}`);
  }
}
