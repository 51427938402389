const allowView = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return ['ADMIN','TEACHER','MONITOR'].includes(user.role);
}

const allowEdit = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return ['ADMIN','TEACHER'].includes(user.role);
}

export {allowEdit, allowView};