import React, { useRef, useState } from 'react';
import axios from 'axios';
import "./UserList.css"
import { MdDelete } from 'react-icons/md';
import { SmallCreateButton } from '../Buttons/Buttons';
import { AddDialog, DeleteDialog } from '../Dialogs/Dialogs';
import { allowEdit } from '../services/permissions';
import { useMessage } from '../MessageContext/MessageContext';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/classes`,
});

const UserList = ({ title, users, role, refreshUsers }) => {
  const deleteDialogRef = useRef(null);
  const addDialogRef = useRef(null);
  const [selectedUserId, setSelectedUser] = useState('');
  const [newUserId, setNewUserId] = useState(''); // For new user input
  const { showMessage } = useMessage();
  const canEdit = allowEdit(); 

  const openDeleteDialog = (user_id) => {
    setSelectedUser(user_id);
    deleteDialogRef.current.showModal();
  };

  const closeDeleteDialog = () => {
    deleteDialogRef.current.close();
  };

  const openAddDialog = () => {
    setNewUserId(''); // Reset new user input
    addDialogRef.current.showModal();
  };

  const closeAddDialog = () => {
    addDialogRef.current.close();
  };

  const confirmDelete = async () => {
    try {
      const curr_class = JSON.parse(localStorage.getItem('selectedClass'));
      await api.patch(`/${curr_class.id}/userdel`, { user_id: selectedUserId })
        .then(() => {
          refreshUsers(); // Refresh the user list
          closeDeleteDialog();
          showMessage("Usuário excluído com sucesso.", 'success');
        })
        .catch((error) => {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao excluir usuário: ${message}`);
        });
    }
    catch (error) {
      showMessage(error.message);
    }
  };
  
  const addUser = async () => {
    if (!newUserId) {
      alert('Please enter a user ID or name before adding.');
      return;
    }

    try {
      const curr_class = JSON.parse(localStorage.getItem('selectedClass'));
      const newUserDetails = { user_id: newUserId, role };

      await api.patch(`/${curr_class.id}/useradd`, newUserDetails)
        .then(() => {
          refreshUsers(); // Refresh the user list
          closeAddDialog();
          showMessage("Usuário adicionado com sucesso.", 'success');

        })
        .catch((error) => {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao adicionar usuário: ${message}`);
        });
    }
    catch (error) {
      showMessage(error.message);
    }
  };

  return (
    <>
      <div className="list-header">
        <h2 className="list-title">
          {title} ({users.length})
        </h2>

        {canEdit && (<SmallCreateButton onClick={openAddDialog} />)}
        
      </div>

      <div className="users">
        {users.map((user, index) => (
          <div className="user" key={index}>
            <label>{user.name || user.user_id}</label>
            {canEdit && (<MdDelete onClick={() => openDeleteDialog(user.user_id)} className="delete-icon" /> )}
          </div>
        ))}
      </div>

      <DeleteDialog
        ref={deleteDialogRef}
        closeDialog={closeDeleteDialog}
        confirmDelete={confirmDelete}
      />

      <AddDialog
        ref={addDialogRef}
        closeDialog={closeAddDialog}
        add={addUser}
        newUser={newUserId}
        setNewUser={setNewUserId}
      />
    </>
  );
};

export default UserList;