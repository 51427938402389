import React, { useState } from 'react';
import { Sidebar, sidebarClasses, Menu, MenuItem } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { CiMenuBurger } from 'react-icons/ci';
import { FaHouse } from 'react-icons/fa6';
import { GoGraph } from 'react-icons/go';
import { IoIosLogOut } from 'react-icons/io';

const SideBarMenu = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClassesClick = () => {
    navigate("/classes")
  }

  const handleLogoutClick = () => {
    localStorage.clear();
    navigate("/")
  }

  return (
    <>
      <Sidebar
        collapsed={isCollapsed}
        rootStyles={{
          height: '-webkit-fill-available',
          [`.${sidebarClasses.container}`]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Menu>
          <MenuItem
            onClick={handleCollapse}
            icon={<CiMenuBurger />}
            className="menu1"
          >
            <div>
              <h2>SuSsy</h2>
            </div>
          </MenuItem>
          <MenuItem icon={<FaHouse className="icon-test" />} onClick={handleClassesClick}>
            Minhas turmas
          </MenuItem>
          <MenuItem icon={<IoIosLogOut />} onClick={handleLogoutClick}>Sair</MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};
export default SideBarMenu;
