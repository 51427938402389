import React from 'react';
import './Tags.css';

const Tag = ({ label, Icon, type}) => {
    return (
      <div className={`tag ${type}`} >
        {Icon && <Icon />}
        <span className="label">{label}</span>
      </div>
    );
};
  
const DoneTag = ({ label, Icon}) => {
    return (<Tag type={"done"} label={label} Icon={Icon} />);
}
 
const ErrorTag = ({ label, Icon}) => {
  return (<Tag type={"error"} label={label} Icon={Icon} />);
}

const WipTag = ({ label, Icon}) => {
  return (<Tag type={"wip"} label={label} Icon={Icon} />);
}

const DraftTag = ({ label, Icon}) => {
  return (<Tag type={"draft"} label={label} Icon={Icon} />);
}
  
export {
  Tag,
  DoneTag,
  ErrorTag,
  WipTag,
  DraftTag
};