import React, { useState, useEffect } from 'react';
import { MainButton } from '../../Buttons/Buttons';
import { DoneTag, WipTag } from '../../Tags/Tags';
import { CiClock1 } from 'react-icons/ci';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BiSolidDownload, BiCheckCircle } from "react-icons/bi";
import { IoMdDownload } from 'react-icons/io';
import { handleGetSubmissionFile, handlePostSubmission } from '../../services/submission';
import { handleGetAssigmentAuxFile, handleGetAssigmentDescription } from '../../services/assignments';
import { handleGetAssignmentSubmissions } from '../../services/assignments';
import { useMessage } from '../../MessageContext/MessageContext';

const SubmitionForm = ({
  assignment,
  submission,
  studentClass,
  changeLastSubmission,
  changeSubmissions,
  changeIsSubmiting
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentSubmissionNumber, setCurrentSubmissionNumber] = useState(assignment.submissions_count);
  const [submiteFile, setSubmiteFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const {showMessage} = useMessage();

  const handleSubmit = async () => {
    setErrorMessage(null);

    if (assignment.status !== "published") {
      setErrorMessage("Esta tarefa não está disponível para submissão.");
      return;
    }

    if (!submiteFile) {
      setErrorMessage("Por favor, selecione um arquivo antes de submeter.");
      return;
    }
  
    const currentDate = new Date();
    const dueDate = new Date(assignment.due_date);
  
    if (currentDate > dueDate) {
      setErrorMessage("O prazo para submissão desta tarefa já expirou.");
      return;
    }

    if (currentSubmissionNumber >= assignment.max_submissions) {
      setErrorMessage("O número máximo de submissões foi atingido.");
      return;
    }

    setIsSubmitting(true);
    changeIsSubmiting(true)

    try {
      await handlePostSubmission(
        assignment.id,
        storedUser.username,
        submiteFile
      );

      startSubmissionPing();
      showMessage("Submissão enviada com sucesso.", "success");
      setCurrentSubmissionNumber(currentSubmissionNumber + 1)
    }
    catch(error) {
      showMessage(error.message);
    }
    finally {
      setIsSubmitting(false);
    }
  };

  const startSubmissionPing = () => {
    const intervalId = setInterval(async () => {
      try {
        const data = await handleGetAssignmentSubmissions();
        const newSubmittedAt = new Date(data.self.submitted_at);

        if (!submission || newSubmittedAt > new Date(submission.submitted_at)) {
          changeLastSubmission(data.self);
          changeSubmissions(data); 
          changeIsSubmiting(false);
        }

        if (data.self?.status === "graded") {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Falha ao atualizar dados da submissão: ",error);
      }
    }, 5000);
  };

  const getSubmissionStatus = (status) => {
    if (status?.toLowerCase() === "graded") {
      return (<DoneTag label='Processada' Icon={BiCheckCircle} />);
    }

    return (<WipTag label='Aguardando Processamento' Icon={CiClock1} />);
  };

  const getDescriptionFile = async () => {
    try {
      await handleGetAssigmentDescription();
    } catch (error) {
      showMessage(error.message);
    }
  };

  const getAuxFile = async () => {
    try {
      await handleGetAssigmentAuxFile();
    } catch (error) {
      showMessage(error.message);
    }
  };

  const getSubmissionFile = async () => {
    try {
      await handleGetSubmissionFile(submission.id);
    } catch (error) {
      showMessage(error.message);
    }
  };

  return (
    <div className="submission-info">
      <div className="submission-header">
        <h2>Olá, {storedUser.name}</h2>
        <label>{studentClass.name}</label>
        <label>{assignment.title}</label>
        <div className="assignment-time">
          <CiClock1 />
          <label>{new Date(assignment.due_date).toLocaleDateString()} 23:59</label>
        </div>
        <div className="buttons">
          <MainButton
            Icon={IoDocumentTextOutline}
            text={'Enunciado'}
            onClick={getDescriptionFile}
          />
          {assignment.aux_file ? (
            <MainButton
              Icon={IoMdDownload}
              text={'Arquivos auxiliares'}
              onClick={getAuxFile}
            />
          ) : <></>}
        </div>
      </div>
      <div className="submission-form">
        <label><b>Arquivo</b></label>
        <input
          type="file"
          id="file"
          className="login-input"
          name="file"
          onChange={(e) => setSubmiteFile(e.target.files[0])}
        />
        <MainButton
          text="Submeter"
          onClick={handleSubmit}
          disabled={isSubmitting || assignment.status !== "published" || new Date() > new Date(assignment.due_date)}
        />
        {isSubmitting && <p className="info-message">Enviando sua submissão, aguarde...</p>}
      </div>
      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}
      {submission && (
        <div className="last-submission">
          <div className="last-submission-header">
            <label><b>Última submissão</b></label>
            {getSubmissionStatus(submission.status)}
          </div>
          <label>Enviada em: {new Date(submission.submitted_at).toLocaleString("pt-BR",{year: 'numeric', month: 'numeric', day: 'numeric', hour:"2-digit", minute:"2-digit"})}</label>
          <MainButton
            text="Baixar"
            Icon={BiSolidDownload}
            onClick={getSubmissionFile}
          />
        </div>
      )}
    </div>
  );
};

export default SubmitionForm;
