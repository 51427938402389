import React, { useState, useEffect } from 'react';
import './Assignments.css';
import { CiClock1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import UserList from '../UserList/UserList';
import Cards from '../Cards/Cards';
import LongMenu from '../Cards/Menu';
import { DraftTag, WipTag, DoneTag } from '../Tags/Tags';
import { handleDeleteAssignment } from '../services/assignments';
import { handleGetClassAssignments } from '../services/classes';
import axios from 'axios';
import EditAssignment from './Edit/EditAssignment';
import './Edit/EditAssignment.css';
import { allowEdit, allowView } from '../services/permissions';
import { useMessage } from '../MessageContext/MessageContext';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/classes`,
});

const ClassTabs = ({ classe }) => {
  const [assignments, setData] = useState([]);
  const navigate = useNavigate();
  const { showMessage } = useMessage();

  const fetchData = async () => {
    try {
      const data = await handleGetClassAssignments();
      setData(data);
    } catch (error) {
      showMessage(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectedClass = JSON.parse(localStorage.getItem('selectedClass'));
    
  const [activeTab, setActiveTab] = useState('tarefas');
  const [userMap, setUserMap] = useState({}); // Store users by role
  const [refresh, setRefresh] = useState(false);

  // Fetch users by role and update the map
  const fetchUsersByRole = async () => {
    const roles = ['TEACHER', 'MONITOR', 'STUDENT'];
    const newUserMap = {};

    try {
      for (const role of roles) {
        try {
          const response = await api.get(
            `/${selectedClass.id}/users?role=${role}`
          );
          newUserMap[role] = response.data;
        }
        catch (error) {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao buscar usuários: ${message}`);
        }
      }
      setUserMap(newUserMap);
    } catch (error) {
      showMessage(error.message);
    }
  };

  // Triggered by UserList after add/delete
  const refreshUsers = () => {
    setRefresh((prev) => !prev);
    fetchUsersByRole(); // Fetch updated user data by role
  };

  useEffect(() => {
    fetchUsersByRole(); // Fetch users by role on component mount
  }, [selectedClass.id, refresh]);

  const handleClick = (assignment) => {
    localStorage.setItem('selectedAssignment', JSON.stringify(assignment));
    navigate('/submissions');
  };

  const handleCreateClick = () => {
    navigate('/assignments/create', { state: { classe } });
  };

  const changeTab = (tab) => {
    console.log('active tab: ' + tab);
    setActiveTab(tab);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState(null);

  const handleEditAssignment = (assignment) => {
    setCurrentAssignment(assignment);
    setIsEditing(true);
  };

  const deleteAssignment = async (assignmentId) => {
    try {
      await handleDeleteAssignment(assignmentId);
      fetchData();
      showMessage('Tarefa excluída com sucesso!', 'success');
    }
    catch (error) {
      showMessage(error.message);
    }
  }

  const handleCloseEdit = () => {
    setIsEditing(false);
    setCurrentAssignment(null);
  };

  const renderContent = () => {
    if (activeTab === 'tarefas') {
      return (
        <>
          <Cards
            cardElements={assignments}
            accessAction={handleClick}
            Header={(props) => getHeader({ ...props, handleEditAssignment, handleDeleteAssignment:deleteAssignment })}
            Content={getCardContent}
            createAction={handleCreateClick}
          />

          {isEditing && (
            <div className="assignment-edit-overlay">
              <EditAssignment
                assignmentId={currentAssignment}
                handleClose={handleCloseEdit}
              />
            </div>
          )}
        </>
      );
    } else if (activeTab === 'usuarios') {
      return (
        <>
          <UserList
            title="Professores"
            users={userMap['TEACHER'] || []}
            role="TEACHER"
            refreshUsers={refreshUsers}
          />
          <UserList
            title="Monitores"
            users={userMap['MONITOR'] || []}
            role="MONITOR"
            refreshUsers={refreshUsers}
          />
          <UserList
            title="Alunos"
            users={userMap['STUDENT'] || []}
            role="STUDENT"
            refreshUsers={refreshUsers}
          />
        </>
      );
    }

    return null;
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
    <SideBarMenu />
    <div>
      {/* Tab Navigation UI */}
      <div className="tab-navigation">
        <button
          className={`tab-button ${activeTab === 'tarefas' ? 'active' : ''}`}   
          onClick={() => changeTab('tarefas')}
        >
          Tarefas
        </button>
        {allowView() && (
          <button
            className={`tab-button ${activeTab === 'usuarios' ? 'active' : ''}`}   
            onClick={() => changeTab('usuarios')}
          >
            Usuários
          </button>
        )}
      </div>

        {/* Content Rendering */}
        <div className="tab-content">{renderContent()}</div>
      </div>
    </div>
  );
};

const getHeader = ({ element: assignment, handleEditAssignment, handleDeleteAssignment }) => {

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <h2 style={{ marginBlockEnd: '0.4em', marginBlockStart: '0.4em' }}>
          {assignment.title}
        </h2>
        {allowEdit() && (
          <LongMenu
            onDelete={() => handleDeleteAssignment(assignment.id)}
            onEdit={() => handleEditAssignment(assignment.id)}
          />
        )}
      </div>
      <div className="assignment-time">
        {allowView() && getAssignmentStatus(assignment.status)}
        <CiClock1 />
        <span>{new Date(assignment.due_date).toLocaleDateString()} 23:59</span>
      </div>
    </>
  );
};

const getAssignmentStatus = (status) => {
  if (status?.toLowerCase() === 'draft') {
    return <DraftTag label="Rascunho" />;
  }

  if (status?.toLowerCase() === 'published') {
    return <WipTag label="Publicado" />;
  }

  return <DoneTag label="Finalizado" />;
};

const getCardContent = ({ element: assignment }) => {
  if (allowView()) {
    return (
      <div className="assignment-card-content">
        <label>
          {assignment.delivered} / {assignment.total_assignees} entregas
        </label>
      </div>
    );
  }

  return (
    <div className="assignment-card-content">
      <label>
        {/* Display total submissions */}
        {assignment.submissions_count || 0}/{assignment.max_submissions} submissões feitas
      </label>
      <label>
        {/* Display success rate or fallback to 0% */}
        {(assignment.success_rate || 0).toLocaleString('pt-BR', { style: 'percent' })} testes aprovados
      </label>
      {assignment.submitted_at && (
      <label>
        {/* Display submission date */}
        Submetido em: {new Date(assignment.submitted_at).toLocaleString('pt-BR')}
      </label>
      )}
    </div>
  );
};

export default ClassTabs;
