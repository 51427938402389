import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import SideBarMenu from '../Sidebar/Sidebar';
import Cards from '../Cards/Cards';
import {
  handleGetUserClasses,
  handleDeleteClass,
  handleCreateClass,
  handleEditClass
} from '../services/classes';
import LongMenu from '../Cards/Menu';
import { CreateClassDialog, EditClassDialog } from '../Dialogs/Dialogs';
import { allowEdit } from '../services/permissions';
import { useMessage } from '../MessageContext/MessageContext';

const UserClasses = () => {
  const [studentClasses, setData] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const { showMessage } = useMessage();

  const fetchData = async () => {
    try {
      const data = await handleGetUserClasses();
      setData(data);
    } catch (error) {
      showMessage(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (refreshTrigger) {
      fetchData();
      setRefreshTrigger(false);
    }
  }, [refreshTrigger]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      <div className="main-content">
      <div className="tab-navigation">
        <button
          className={`tab-button active`}
          disabled
        >
          Minhas Turmas
        </button>
      </div>
        <Classes 
          studentClasses={studentClasses} 
          triggerRefresh={() => setRefreshTrigger(true)}
        />
      </div>
    </div>
  );
};

const Classes = ({ studentClasses, triggerRefresh }) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const createDialogRef = useRef(null);
  const editDialogRef = useRef(null);

  const handleClick = (studentClass) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const filtered = studentClass.users?.filter(u => u.user_id === user.username);
    const localRole = filtered?.length ? filtered[0].role : user.role;
    user.role = localRole;
    
    localStorage.setItem('selectedClass', JSON.stringify(studentClass));
    localStorage.setItem('user', JSON.stringify(user));
    navigate('/assignments');
  };

  const openAddDialog = () => {
    createDialogRef.current.showModal();
  };

  const closeAddDialog = () => {
    createDialogRef.current.close();
  };

  const createNewClass = async (
    name,
    studentFile,
    teacherFile,
    monitorFile
  ) => {

    try {
      await handleCreateClass(name, studentFile, teacherFile, monitorFile);
      triggerRefresh();
      showMessage('Classe criada com sucesso!', 'success');
      closeAddDialog();
    }
    catch (error) {
      showMessage(error.message);
    }
  };

  const openEditDialog = (classe) => {
    editDialogRef.current.setDialogData(classe);
    editDialogRef.current.showDialog();
  };

  const closeEditDialog = () => {
    editDialogRef.current.closeDialog();
  };

  const editClass = async (currentClass, name) => {
    try {
      await handleEditClass(currentClass, name);
      triggerRefresh();
      showMessage('Classe editada com sucesso!', 'success');
      closeEditDialog();
    }
    catch (error) {
      showMessage(error.message);
    }
  };

  const deleteClass = async (classes) => {
    try {
      await handleDeleteClass(classes.id);
      triggerRefresh();
      showMessage('Classe excluída com sucesso!', 'success');
    }
    catch (error) {
      showMessage(error.message);
    }
  };

  return (
    <>
      <Cards
        cardElements={studentClasses}
        accessAction={handleClick}
        Header={(pros) => getHeader({ ...pros, editAction: openEditDialog, deleteAction: deleteClass })}
        createAction={openAddDialog}
      />
      <CreateClassDialog
        ref={createDialogRef}
        closeDialog={closeAddDialog}
        add={createNewClass}
      />
      <EditClassDialog
        ref={editDialogRef}
        close={closeEditDialog}
        edit={editClass}
      />
      ;
    </>
  );
};

const getHeader = ({ element: classes, editAction, deleteAction }) => {

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <h2>{classes.name}</h2>
        {allowEdit() && (
          <LongMenu
            onDelete={() => deleteAction(classes)}
            onEdit={() => editAction(classes)}
          />
        )}
      </div>
    </>
  );
};

export default UserClasses;