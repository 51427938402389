import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './EditAssignment.css';
import '../Assignments'
import { useMessage } from '../../MessageContext/MessageContext';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

const EditAssignment = ({ assignmentId, handleClose }) => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();

  const [assignment, setAssignment] = useState({
    title: '',
    status: 'draft',
    dueDate: 'Tue, 05 Nov 2024 20:09:41',
    maxSubmissions: 1,
    statement: null,
    auxiliaryFiles: null,
    tests: [], // Initialize as an empty array
  });
  
  const [newTest, setNewTest] = useState({
    input: null,
    output: null,
    isOpen: false,
  });  

  // Fetch existing assignment data when the component loads
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const credentials = btoa(`${storedUser.username}:${storedUser.role}`);
    
    const fetchAssignment = async () => {
      try {
        // Fetch core assignment data
        const assignmentData = await api.get(`/assignments/${assignmentId}`, {
          headers: { Authorization: `Basic ${credentials}` },
        });

        const [statementData, testsData, auxFilesData] = await Promise.all([
          api.get(`/assignments/${assignmentId}/description`, {
            headers: { Authorization: `Basic ${credentials}` },
          }),
          api.get(`/assignments/${assignmentId}/tests`, {
            headers: { Authorization: `Basic ${credentials}` },
          }),
          api.get(`/assignments/${assignmentId}/aux`, {
              headers: { Authorization: `Basic ${credentials}` },
          })
          .catch((auxError) => {
              if (auxError.response && auxError.response.status !== 404) {
                const message = auxError.response?.data?.message || auxError.response?.data?.error || auxError.message; 
                throw new Error(`Falha ao recuperar dados da tarefa: ${message}`);
              } else {
                console.log("Aux file not found. Handling gracefully.");
              }
              return { data: null }; // Return an object with a `data` key to avoid undefined access
            }),
        ]);

        console.log("Files returned:", statementData, auxFilesData);

        // Set the state with all fetched data
        setAssignment({
          title: assignmentData.data.title,
          status: assignmentData.data.status,
          dueDate: assignmentData.data.due_date,
          maxSubmissions: assignmentData.data.max_submissions,
          statement: statementData.data,
          auxiliaryFiles: auxFilesData.data,
          tests: testsData.data.map((test) => ({ id: test.id, isOpen: test.is_open }))
        });
      } catch (error) {
        showMessage(error.message);
      }
    };

    fetchAssignment();
  }, [assignmentId, navigate, showMessage]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'statement' || name === 'auxiliaryFiles') {
      setAssignment({ ...assignment, [name]: files[0] });
    } else {
      setAssignment({ ...assignment, [name]: value });
    }
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
  
    setAssignment((prev) => ({
      ...prev,
      [type]: file,
    }));
  };  

  const handleSubmit = async () => {
    try {
      if (!assignment.title || !assignment.dueDate || !assignment.statement) {
        showMessage("Campos marcados (*) são obrigatórios.");
        return;
      }

      const formData = new FormData();
      formData.append("title", assignment.title);
      formData.append("status", assignment.status);
      formData.append("due_date", assignment.dueDate);
      formData.append("max_submissions", assignment.maxSubmissions);
      formData.append("description", assignment.statement);
    
      if (assignment.auxiliaryFiles) formData.append("aux", assignment.auxiliaryFiles);

      try {
        await api.post(`/assignments/${assignmentId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
      }
      catch (error) {
        const message = error.response?.data?.message || error.response?.data?.error || error.message; 
        throw new Error(`Falha ao atualizar tarefa: ${message}`);
      } 


      // After assignment is updated, add tests individually
      const createTests = assignment.tests.filter((test) => test.status === "created");
      const updateTests = assignment.tests.filter((test) => test.status === "updated");
      const removeTests = assignment.tests.filter((test) => test.status === "removed");
    
      const createPromises = createTests.map(async (test) => {
        const formData = new FormData();
        formData.append("assignment_id", assignmentId);
        if (test.input) formData.append("input", test.input);
        if (test.output) formData.append("output", test.output);
        formData.append("open", test.isOpen ? "true" : "false");

        try {
          return await api.post(`/tests`, formData);
        } catch (error) {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao criar novo teste: ${message}`);
        }
      });
      
      const updatePromises = updateTests.map(async (test) => {
        try {
          return await api.put(`/tests/${test.id}`, {
            is_open: test.isOpen,
          });
        } catch (error) {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao atualizar teste: ${message}`);
        }
      });
      
      const removePromises = removeTests.map((test) =>
        api.delete(`/tests/${test.id}`).catch((error) => {
          const message = error.response?.data?.message || error.response?.data?.error || error.message; 
          throw new Error(`Falha ao excluir teste: ${message}`);
        })
      );
    
      // Execute all promises
      await Promise.all([...createPromises, ...updatePromises, ...removePromises]);
      navigate(0);
    } catch (error) {
      showMessage(error.message);
    }
  };  

  // Add a new test
  const handleAddTest = () => {
    if (!newTest.input || !newTest.output) {
      alert("Por favor, selecione os arquivos .in e .res para adicionar um teste.");
      return;
    }

    setAssignment((prevAssignment) => ({
      ...prevAssignment,
      tests: [
        ...prevAssignment.tests,
        { ...newTest, status: "created" },
      ],
    }));

    // Reset the form
    setNewTest({ input: null, output: null, isOpen: false });
  };

  // Update a test field
  const handleTestChange = (index, field, value) => {
    setAssignment((prevAssignment) => {
      const updatedTests = [...prevAssignment.tests];
      updatedTests[index] = {
        ...updatedTests[index],
        [field]: value,
        status: updatedTests[index].status === "created" ? "created" : "updated",
      };

      return { ...prevAssignment, tests: updatedTests };
    });
  };

  // Remove a test
  const handleRemoveTest = (index) => {
    setAssignment((prevAssignment) => {
      const updatedTests = [...prevAssignment.tests];
      if (updatedTests[index].status === "created") {
        // Remove completely if newly created
        updatedTests.splice(index, 1);
      } else {
        // Mark as removed
        updatedTests[index] = { ...updatedTests[index], status: "removed" };
      }

      return { ...prevAssignment, tests: updatedTests };
    });
  };

  const formatToDateTimeLocal = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      throw new Error(`Invalid date string: ${dateString}`);
    }
  
    return date.toISOString().slice(0, 16); // Extract 'YYYY-MM-DDTHH:mm'
  };  

  return (
    <div className="assignment-edit-overlay">
      <div className="assignment-edit-container">
        {/* Left Column: Form */}
        <div className="assignment-edit-form">
          <button onClick={handleClose} className="close-button">✖</button>
          <h2>Editar Tarefa</h2>
          
          <div className="form-group">
            <label htmlFor="title">Título *</label>
            <input
              type="text"
              id="title"
              name="title"
              value={assignment.title}
              onChange={handleChange}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              value={assignment.status}
              onChange={handleChange}
            >
              <option value="draft">Rascunho</option>
              <option value="published">Publicada</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="dueDate">Data de submissão máxima *</label>
            <input
              type="datetime-local"
              id="dueDate"
              name="dueDate"
              value={formatToDateTimeLocal(assignment.dueDate)} // Format the date here
              onChange={handleChange}
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="maxSubmissions">Máximo de submisões</label>
            <input
              type="number"
              id="maxSubmissions"
              name="maxSubmissions"
              value={assignment.maxSubmissions}
              min="1"
              onChange={handleChange}
            />
          </div>

          <div className="upload-section">
            <div className="upload-item">
              <label htmlFor="description-upload" className="upload-label">
                Upload da descrição *
              </label>
              <div className="upload-container">
                <input
                  type="file"
                  id="description-upload"
                  className="file-input"
                  onChange={(e) => handleFileChange(e, "descriptionFile")}
                />
                <span className="file-name">
                  {assignment.descriptionFile
                    ? assignment.descriptionFile.name
                    : "Nenhum arquivo escolhido"}
                </span>
              </div>
            </div>

            <div className="upload-item">
              <label htmlFor="aux-upload" className="upload-label">
                Upload de arquivos auxiliares
              </label>
              <div className="upload-container">
                <input
                  type="file"
                  id="aux-upload"
                  className="file-input"
                  accept=".zip"
                  onChange={(e) => handleFileChange(e, "auxiliaryFiles")}
                />
                <span className="file-name">
                  {assignment.auxiliaryFiles
                    ? assignment.auxiliaryFiles.name
                    : "Nenhum arquivo escolhido"}
                </span>
              </div>
            </div>
          </div>
        </div>
  
        {/* Tests Section */}
        <div className="assignment-edit-tests">
          <h3>Testes</h3>

          {/* Compact List of Added Tests */}
          <div className="tests-grid">
            {assignment.tests.map((test, index) => (
              <div
                key={index}
                className="test-item compact"
                style={{
                  backgroundColor: test.status === "updated" ? "#fffbe6" : test.status === "removed" ? "#E07B7C" : "#f9f9f9", // Highlight yellow if updated
                }}
              >
                <span className="test-name">
                  {test.input ? test.input.name : "Sem arquivo (.in)"},{" "}
                  {test.output ? test.output.name : "Sem arquivo (.res)"}
                </span>
                <div className="test-actions">
                  <label>
                    <input
                      type="checkbox"
                      checked={test.isOpen}
                      onChange={(e) => handleTestChange(index, "isOpen", e.target.checked)}
                    />
                    Aberto
                  </label>
                  <button
                    onClick={() => handleRemoveTest(index)}
                    className="remove-btn"
                  >
                    🗑️
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Section to Add a New Test */}
          <div className="add-test-section">
            <div className="file-inputs">
              <input
                type="file"
                id="new-test-input"
                className="file-btn"
                onChange={(e) =>
                  setNewTest((prev) => ({ ...prev, input: e.target.files[0] }))
                }
              />
              <input
                type="file"
                id="new-test-output"
                className="file-btn"
                onChange={(e) =>
                  setNewTest((prev) => ({ ...prev, output: e.target.files[0] }))
                }
              />
            </div>
            <button onClick={handleAddTest} className="add-btn">
              Adicionar Teste
            </button>
          </div>
        </div>

        {/* Save Button */}
        <div className="save-button-container">
          <button className="save-button" onClick={handleSubmit}>
            Salvar
          </button>
        </div>
      </div>
    </div>
  );  
}

export default EditAssignment;
