import React from 'react';
import './App.css';
import Login from './Login/Login';
import Submission from './Submission/Submission';
import ClassTabs from './Assignments/Assignments';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserClasses from './Classes/Classes';
import TaskForm from './Assignments/Create/CreateAssignment';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/classes" element={<UserClasses />} />
        <Route path="/assignments" element={<ClassTabs />} />
        <Route path="/submissions" element={<Submission />} />
        <Route path="/assignments/create" element={<TaskForm />} />
      </Routes>
    </Router>
    // <>
    //   <TaskForm />
    // </>
  );
}

export default App;
