import React, { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { CiLock } from 'react-icons/ci';
import { handleGetAssignmentTests } from '../../services/assignments';
import { handleGetTest } from '../../services/tests';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import { allowView } from '../../services/permissions';
import { useMessage } from '../../MessageContext/MessageContext';

const TestPanel = ({submission, isSubmiting}) => {
  const [showTestInformation, setShowTestInformation] = useState(false);

  return showTestInformation
  ? (<TestInformation 
      results={submission?.results}
      showTestInformation={showTestInformation} 
      setShowTestInformation={setShowTestInformation}
      />)
  : (<TestsDashboard
      submission={submission}
      showTestInformation={showTestInformation} 
      setShowTestInformation={setShowTestInformation}
      isSubmiting={isSubmiting}
      />);
};

const TestsDashboard = ({
  submission,
  showTestInformation,
  setShowTestInformation,
  isSubmiting
}) => {
  const [openTests, setOpenTests] = useState([]);
  const [closedTests, setClosedTests] = useState([]);
  const { showMessage } = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await handleGetAssignmentTests();
        setOpenTests(data.filter((test) => test.is_open));
        setClosedTests(data.filter((test) => !test.is_open));
      } catch (error) {
        showMessage(error.message);
      }
    };

    fetchData();
  }, []);

  const countPassedTests = (tests, results) => {
    return tests.reduce((count, test) => {
      const result = results?.find((r) => r.test_id === test.id);
      return count + (result?.passed ? 1 : 0);
    }, 0);
  };

  const passedOpenTests = countPassedTests(openTests, submission?.results);
  const passedClosedTests = countPassedTests(closedTests, submission?.results);

  const renderTestStatus = (is_open, status) => {
    if (isSubmiting) {
      return <FaSpinner className="spinner submission-status" />;
    }

    if (!is_open) {
      return <CiLock className="submission-status" />;
    }

    return null;
  };

  const getTestBackgroundColor = (test, results) => {
    const result = results?.find((r) => r.test_id === test.id);

    if (!result) {
      return '#FFF'; // Fundo branco por padrão
    }

    if (!result.passed) {
      return '#FFCDD2'; // Vermelho pastel
    }

    return '#C8E6C9'; // Verde pastel
  };

  const handleShowTestInformationClick = (test, index) => {
    test.index = index;
    localStorage.setItem("selectedTest", JSON.stringify(test));
    setShowTestInformation(!showTestInformation);
  };

  return (
    <div className="test-cases">
      <div className="cases open-cases">
        <label>Testes abertos ({passedOpenTests}/{openTests.length})</label>
        <div className="tests">
          {openTests?.length ? (
            openTests.map((test, index) => (
              <div
                key={index}
                className="test"
                style={{
                  backgroundColor: getTestBackgroundColor(test, submission?.results),
                }}
                onClick={() => handleShowTestInformationClick(test, index)}
              >
                <span>Teste {index + 1}</span>
                {renderTestStatus(true, submission?.status)}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="cases close-cases">
        <label>Testes fechados ({passedClosedTests}/{closedTests.length})</label>
        <div className="tests">
          {closedTests?.length ? (
            closedTests.map((test, index) => (
              <div
                className="test"
                key={index}
                style={{
                  backgroundColor: getTestBackgroundColor(test, submission?.results),
                }}
                onClick={
                  allowView() ? () => handleShowTestInformationClick(test, index) : undefined
                }
              >
                <span>Teste {index + 1}</span>
                {renderTestStatus(false, submission?.status)}
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const TestInformation = ({ results, showTestInformation, setShowTestInformation }) => {
  const test = JSON.parse(localStorage.getItem("selectedTest")); 
  const [data, setData] = useState({});
  const [result, setResult] = useState({});
  const [sideBySide, setSideBySide] = useState(false);
  const [diffVisible, setDiffVisible] = useState(false);
  const {showMessage} = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleGetTest();
        setData(response);
        setResult(results?.filter(r => r.test_id === response?.id)[0]);
      }
      catch (error) {
        showMessage(error.message);
      }
    };

    if (showTestInformation) {
      fetchData();
    }
  }, [showTestInformation]);

  const handleCheckboxChange = (e) => {
    setSideBySide(e.target.checked);
  };

  const handleDiffVisibleClick = () => {
    setDiffVisible(!diffVisible);
  }

  return (
    <div className="test-cases">
      <div className="test-info-header">
        <h2>Teste {test.index + 1}</h2>
        <IoMdClose
          onClick={() => setShowTestInformation(!showTestInformation)}
          size={25}
          className="close-test-info"
        />
      </div>
      <label>Entrada:</label>
      <textarea disabled value={data?.input} className="custom-textarea" />

      <label>Saída esperada:</label>
      <textarea disabled value={data?.output} className="custom-textarea" />

      {result && (
        <>
          <label>Saída obtida:</label>
          <textarea disabled value={result?.result} className="custom-textarea" /> 

          <a href='#diff-viewer' className='diff-section-header' onClick={handleDiffVisibleClick}>
            <div className='diff-unified-view-option'>
              <input type='checkbox' onClick={(e) => e.stopPropagation() } checked={sideBySide} onChange={handleCheckboxChange}/>
              Visualizar diferenças lado a lado
            </div>
            {diffVisible ? <IoChevronUp /> : <IoChevronDown />}
          </a>
          <div id="diff-viewer" className={diffVisible ? 'diff-viewer' : 'hidden-diff-viewer'}>
            <ReactDiffViewer 
              oldValue={data?.output} 
              newValue={result?.result} 
              splitView={sideBySide} /> 
          </div>
        </>
      )}
    </div>    
  );
};

export { TestPanel };
