import React from 'react';

function findStudentsWithoutRecord(classData, submissions) {
    const students = classData.users.filter(user => user.role === "STUDENT").map(user => user.user_id);
    const registeredUserIds = submissions.others.map(entry => Object.keys(entry)[0]);
  
    return students.filter(studentId => !registeredUserIds.includes(studentId));
}

function getStudentSubmissionData(userId, submissions) {
    const studentRecord = submissions.others.find(entry => entry[userId]);

    if (!studentRecord) return null;
    
    const { submissions_count, results } = studentRecord[userId];
    const totalTests = results ? results.length : 0;
    const passedTests = results ? results.filter(result => result.passed).length : 0;
    
    return {
        submissionsCount: submissions_count,
        testsPassed: passedTests,
        totalTests: totalTests,
        fullData: studentRecord[userId]
    };
}

const Table = ({ submissions, changeLastSubmission }) => {
  const classData = JSON.parse(localStorage.getItem('selectedClass'));

  if (!classData) {
    return <div>Nenhuma classe selecionada.</div>;
  }

  const students = classData.users.filter(user => user.role === "STUDENT");
  const studentsWithoutRecord = findStudentsWithoutRecord(classData, submissions);

  const handleTestClick = (submissionData) => {
    if (submissionData && submissionData.fullData) {
      changeLastSubmission(submissionData.fullData);
    }
  };

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Aluno</th>
          <th>Submissões</th>
          <th>Testes aprovados</th>
        </tr>
      </thead>
      <tbody>
        {students.length > 0 ? (
          students.map(student => {
            const hasRecord = !studentsWithoutRecord.includes(student.user_id);
            const submissionData = hasRecord ? getStudentSubmissionData(student.user_id, submissions) : null;

            const submissionsCount = hasRecord && submissionData ? submissionData.submissionsCount : 0;
            const testsPassed = hasRecord && submissionData ? submissionData.testsPassed : 0;
            const totalTests = hasRecord && submissionData ? submissionData.totalTests : 0;

            return (
              <tr 
                key={student.user_id} 
                onClick={() => handleTestClick(submissionData)} 
                style={{ cursor: 'pointer' }}
              >
                <td>
                  {student.name ? (
                    <>
                    {`${student.name} `}
                      <span style={{fontSize:'0.8em', color:'gray'}}>({student.user_id})</span>
                    </>
                  ):
                  student.user_id}
                </td>
                <td>{submissionsCount}</td>
                <td>{totalTests ? `${testsPassed}/${totalTests}`: "-"}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="3">Nenhum estudante encontrado.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
