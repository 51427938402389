import React, { useEffect, useState } from 'react';
import './Submission.css';
import SideBarMenu from '../Sidebar/Sidebar';
import SubmitionForm from './components/SubmitionForm';
import Table from './components/SubmissionTable';
import { TestPanel } from './components/Tests';
import { handleGetAssignmentSubmissions } from '../services/assignments';
import { allowView } from '../services/permissions';
import { useMessage } from '../MessageContext/MessageContext';

const Submission = () => {
  const {showMessage} = useMessage();
  const studentClass =  JSON.parse(localStorage.getItem('selectedClass'));
  const assignment = JSON.parse(localStorage.getItem('selectedAssignment'));

  const [submissions, setSubmissions] = useState({});
  const [lastSubmission, setLastSubmissions] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await handleGetAssignmentSubmissions();
        setSubmissions(data);
        setLastSubmissions(data.self);
      }
      catch(error) {
        showMessage(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <SideBarMenu />
      {allowView() ? (
        <Tabs
          assignment={assignment}
          studentClass={studentClass}
          submissions={submissions}
          lastSubmission={lastSubmission}
          changeLastSubmission={setLastSubmissions}
          changeSubmissions={setSubmissions}
          isSubmiting={isSubmiting}
          setIsSubmiting={setIsSubmiting}
        />
      ) : (
        <SubmissionPage
          assignment={assignment}
          studentClass={studentClass}
          lastSubmission={lastSubmission}
          changeLastSubmission={setLastSubmissions}
          changeSubmissions={setSubmissions}
          isSubmiting={isSubmiting}
          setIsSubmiting={setIsSubmiting}
        />
      )}
    </div>
  );
};

const SubmissionPage = ({
  assignment,
  studentClass,
  lastSubmission,
  changeLastSubmission,
  changeSubmissions,
  isSubmiting,
  setIsSubmiting
}) => {

  return (
    <div className="submission-page">
      <SubmitionForm
        submission={lastSubmission}
        assignment={assignment}
        studentClass={studentClass}
        changeLastSubmission={changeLastSubmission}
        changeSubmissions={changeSubmissions}
        changeIsSubmiting={setIsSubmiting}
      />
      <TestPanel
        submission={lastSubmission}
        isSubmiting={isSubmiting}
      />
    </div>
  );
};

const Tabs = ({
  assignment,
  studentClass,
  submissions,
  changeLastSubmission,
  lastSubmission,
  changeSubmissions,
  isSubmiting,
  setIsSubmiting
}) => {
  const [activeTab, setActiveTab] = useState('tarefa');

  const renderContent = () => {
    if (activeTab === 'tarefa') {
      return (
        <SubmissionPage
          assignment={assignment}
          studentClass={studentClass}
          submissions={submissions}
          lastSubmission={lastSubmission}
          changeLastSubmission={changeLastSubmission}
          changeSubmissions={changeSubmissions}
          isSubmiting={isSubmiting}
          setIsSubmiting={setIsSubmiting}
        />
      );
    }

    return (
      <div className="submission-page">
        <div className="submission-info">
          <Table submissions={submissions} changeLastSubmission={changeLastSubmission} />
        </div>
        <TestPanel
          submission={lastSubmission}
          isSubmiting={isSubmiting}
        />
      </div>
    );
  };

  return (
    <div className="tab-container">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'tarefa' ? 'active' : ''}`}
          onClick={() => {setActiveTab('tarefa'); changeLastSubmission(submissions.self);}}
        >
          Tarefa
        </button>
        <button
          className={`tab-button ${activeTab === 'pessoas' ? 'active' : ''}`}
          onClick={() => setActiveTab('pessoas')}
        >
          Alunos
        </button>
      </div>

      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default Submission;
