import React, { useState, createContext, useContext } from 'react';
import './MessageContext.css';

const MessageContext = createContext();

const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [type, setType] = useState('error');

  const showMessage = (message, type='error') => {
    setMessage(message);
    setType(type);
  }

  setTimeout(() => {
    setMessage('');
    setType('error');
  }, 5000);

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {children}
      { message && (
        <div className={`${type}-message`}>
          {message}
        </div>
      )}
    </MessageContext.Provider>
  );
};

const useMessage = () => useContext(MessageContext);

export {MessageProvider, useMessage};
