import axios from 'axios';
import { Base64 } from 'js-base64';

const baseURL = `${process.env.REACT_APP_BASE_URL}/tests`;

const api = axios.create({
  baseURL:baseURL
});


export const handleGetTest = async () => {
  try {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const selectedTest = JSON.parse(localStorage.getItem('selectedTest'));
    const credentials = Base64.encode(
      `${storedUser.username}:${storedUser.role}`
    );
    const { data } = await api.get(`/${selectedTest.id}`, {
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });

    return data;
  }
  catch (error) {
    const message = error.response?.data?.message || error.response?.data?.error || error.message; 
    throw new Error(`Falha ao recuperar informações do teste: ${message}`);
  } 
};